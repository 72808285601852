import gtag, { install } from 'ga-gtag';
install('G-VZZCC8MLD1');
import Rails from "@rails/ujs"

document.addEventListener("turbolinks:load", function() {
  // document.addEventListener('DOMContentLoaded', (event) => {
    'use strict';

    var body = $('#create_session_form');
    if (body.length) {

      function goToNextInput(e) {
        var key = e.which,
          t = $(e.target),
          sib = t.next('input');

        if (key != 9 && (key < 48 || key > 57)) {
          e.preventDefault();
          return false;
        }

        if (key === 9) {
          return true;
        }

        if (!sib || !sib.length) {
          sib = body.find('input').eq(0);
        }
        sib.select().focus();
      }

      function goToPreviousInput(e) {
        var key = e.which,
          t = $(e.target),
          sib = t.prev('input');


        if (key === 8) {
          if (!sib || !sib.length) {
            sib = body.find('input').eq(0);
          }
          sib.select().focus();
          sib.val('');
          t.val('');
        }

      }

      function onKeyDown(e) {
        var key = e.which;

        if (key === 9 || (key >= 48 && key <= 57)) {
          return true;
        }

        e.preventDefault();
        return false;
      }
      
      function onFocus(e) {
        $(e.target).select();
      }
       function submit(e) {
         var key = e.which,
          t = $(e.target);

        if (t.attr('name') == "code_input_6") {
          var empty_input = false;
          var inputs = $("#create_session_form input.form-control");
          inputs.each((index, item) => {
            console.log(item.value)
            if (item.value == '') {
              console.log("item: "+ item)
              empty_input = true
            }
          });
          if (!empty_input) {
            var form = document.querySelector('form#create_session_form');
            Rails.fire(form, 'submit');
            clearInterval(timer)
            // body.submit()
          }
        }
        
      }

      body.on('keyup', 'input', goToNextInput);
      body.on('keyup', 'input', goToPreviousInput);
      body.on('keyup', 'input', submit);
      body.on('keydown', 'input', onKeyDown);
      body.on('click', 'input', onFocus);

      var time_sent = Date.now();
      var timer = setInterval(function() {
        let time_passed = Math.floor((time_sent - Date.now() + 300000)/1000);
        if (time_passed > 0) {
          // count down
          $("#timer_valid_msg").show();
          $("#timer_invalid_msg").hide();
          document.getElementById("timer").innerHTML = time_passed;
        } else {
          // verification timeout
          $("#timer_valid_msg").hide();
          $("#timer_invalid_msg").show();
          document.getElementById("timer").innerHTML = "";
        }
      }, 1000)

      $("#resend_verification_code_form").submit(() => {
        gtag('event', 'resend_signin_verification_code', {});
        clearInterval(timer)
      })

      var first_input = $("#create_session_form input.form-control[name='code_input_1']");
      first_input[0].focus();
    }
  // });
});


document.addEventListener("turbolinks:load", function() {
  $("#phone_verification_form").on("ajax:success", (event) => {
    gtag('event', 'initiate_signin', {});
  }).on("ajax:error", (event) => {
    console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });
  $("#create_session_form").on("ajax:success", (event) => {
      gtag('event', 'signin', {"method": "sms"});
      gtag('config', 'G-VZZCC8MLD1', {
        'user_id': $("input[name='user_id']").val()
      });
  }).on("ajax:error", (event) => {
    console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });
  

  
});
